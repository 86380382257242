'use strict';

import IsType from '@util/is-type';

export default {
    /**
     * Регистронезависимый поиск
     *
     * @param {String} _source
     * @param {String} _search
     * @return {Boolean}
     * @throw Error
     */
    search(_source, _search) {
        if (!IsType.isString(_source)) {
            throw new Error('Параметр "Источник" не поддерживается!');
        }
        if (!IsType.isString(_search)) {
            throw new Error('Параметр "Искомая строка" не поддерживается!');
        }

        return _source.toLowerCase().includes(_search.toLowerCase());
    },

    /**
     * Получить случайную строку
     *
     * @return {string}
     */
    random() {
        const arr = new Uint32Array(1);
        window.crypto.getRandomValues(arr);

        return String(arr[0]);
    },

    /**
     * Добавление ведущего нуля перед числом
     *
     * @param {String|Number} _value
     * @param {Number} _length
     * @return {string}
     * @throw Error
     */
    addPrefixZero(_value, _length) {
        if (!IsType.isString(_value) && !IsType.isNumber(_value)) {
            throw new Error('Параметр строка некорректный!');
        }
        if (!IsType.isNumber(_length)) {
            throw new Error('Параметр длина строки некорректный!');
        }

        return _value.toString().padStart(_length, '0');
    },

};