'use strict';

import {readonly, ref} from 'vue';
import Iterable        from '@util/iterable';
import IsType          from '@util/is-type';
import StringUtil      from '@util/string';
import Convert         from '@util/convert';

const data    = ref({});
let listeners = null;

const prefix       = 'localStorageData';
const defaultValue = null;

/**
 * Сохранение данных в глобальном хранилище
 */
const flush = () => {
    window.localStorage.setItem(prefix, JSON.stringify(data.value));
};

/**
 * Генерация события на изменение хранилища
 *
 * @param {String} _key
 * @param {unknown} _new
 * @param {unknown} _old
 */
const eventTrigger = (_key, _new, _old) => {
    window.dispatchEvent(new CustomEvent(prefix, {
        detail: {
            new: _new,
            old: _old,
            key: _key,
        },
    }));
};

/**
 * Экстрация всех данных из глобольного хранища
 *
 * @return {Object}
 */
const getStorageData = () => {
    let storageData = window.localStorage.getItem(prefix);
    if (storageData !== null) {
        try {
            storageData = JSON.parse(storageData);

            return storageData;
        } catch {
            return {};
        }
    }

    return {};
};

const methods = {
    /**
     * Наличие ячейки в хранилище
     *
     * @param {String} _key
     * @return {Boolean}
     */
    has(_key) {
        if (!IsType.isString(_key)) {
            throw new Error('Некорректный ключ првоерки данных локального хранилища!');
        }

        return IsType.isDefined(data.value[_key]);
    },
    /**
     * Получение данных из храниллища
     *
     * @param {String} _key
     * @param {unknown|null} _default
     * @return {unknown}
     */
    get(_key, _default = defaultValue) {
        if (!IsType.isString(_key)) {
            throw new Error('Некорректный ключ получения данных локального хранилища!');
        }

        return this.has(_key) ? data.value[_key] : _default;
    },
    /**
     * Установка данных в хранилище
     *
     * @param {String} _key
     * @param {unknown} _value
     */
    set(_key, _value) {
        if (!IsType.isString(_key)) {
            throw new Error('Некорректный ключ установки данных локального хранилища!');
        }

        eventTrigger(_key, _value, this.get(_key));

        data.value[_key] = _value;

        flush();
    },
    /**
     * Удаление данных из хранилища
     *
     * @param {String} _key
     */
    remove(_key) {
        if (!IsType.isString(_key)) {
            throw new Error('Некорректный ключ удаления данных локального хранилища!');
        }

        eventTrigger(_key, defaultValue, this.get(_key));
        delete data.value[_key];

        flush();
    },
    /**
     * Обновление хранилища из глобального хранилища
     */
    refresh() {
        data.value = getStorageData();

        Iterable.each(listeners, (_event) => {
            eventTrigger(_event.key, this.get(_event.key), defaultValue);
        });
    },
    /**
     * Установка наблюдателя за ячейкой хранилища
     *
     * @param {String} _key
     * @param {Function} _callback
     * @param {Boolean} _isRunNow
     * @return {Function}
     */
    watch(_key, _callback, _isRunNow = false) {
        if (!IsType.isString(_key)) {
            throw new Error('Некорректный ключ наблюдателя локального хранилища!');
        }
        if (!IsType.isFunction(_callback)) {
            throw new Error('Некорректная функция обратного вызова наблюдателя локального хранилища!');
        }

        const keyEvent = StringUtil.random();

        const eventCallback = (_event) => {
            if (_event.detail.key === _key) {
                _callback(_event.detail.new, _event.detail.old);
            }
        };

        const removeWatch = () => {
            window.removeEventListener(prefix, eventCallback);
            delete listeners[keyEvent];
        };

        const createWatch = () => {
            listeners[keyEvent] = {
                key     : _key,
                callback: _callback,
            };

            window.addEventListener(prefix, eventCallback);
        };

        createWatch();

        if (_isRunNow) {
            eventTrigger(_key, this.get(_key), defaultValue);
        }

        return removeWatch;
    },
};

/**
 * Слушатель глобального собяти на изменение хранилища
 *
 * @param {Object} _event
 */
const callbackGlobalEvent = (_event) => {
    if (_event.storageArea === window.localStorage && !Convert.isEqual(getStorageData(), data.value)) {
        methods.refresh();
    }
};

/**
 * Методы управления хранилищем
 */
export default readonly(methods);

/**
 * Инициализация хранилища
 */
export const init = () => new Promise((_resolve) => {
    listeners = {};

    window.removeEventListener('storage', callbackGlobalEvent);
    window.addEventListener('storage', callbackGlobalEvent);

    methods.refresh();

    _resolve();
});