'use strict';

import IsType   from '@util/is-type';
import Iterable from '@util/iterable';

export default {
    /**
     * Получить все значения в виде массива
     *
     * @param {Object|Array} _param
     * @return {unknown[]}
     * @throw Error
     */
    toArray(_param) {
        if (IsType.isIterable(_param)) {
            return Object.values(_param);
        }

        throw new Error('Параметр для конвертации в массив не поддерживается!');
    },

    /**
     * Приведение псевдобулевых значений к истино булевым
     * 'true' => true
     * 'false' => false
     *
     * @param {Object|Array} _param
     * @return {Boolean}
     * @throw Error
     */
    toBoolean(_param) {
        if (IsType.isBool(_param, true)) {
            if (_param === 'true') {
                return true;
            }
            if (_param === 'false') {
                return false;
            }
        }

        throw new Error('Параметр для конвертации в истино булево не поддерживается!');
    },

    /**
     * Получить все значения в виде объекта
     *
     * @param {Object|Array} _param
     * @return {unknown[]}
     * @throw Error
     */
    toSimpleObject(_param) {
        if (IsType.isIterable(_param)) {
            const result = {};
            Object.assign(result, _param);

            return result;
        }

        throw new Error('Параметр для конвертации в простой объект не поддерживается!');
    },

    /**
     * Вычисление хеша
     *
     * @param {Object|Array} _param
     * @return {Number}
     * @throw Error
     */
    hash(_param) {
        if (IsType.isIterable(_param) || IsType.isString(_param) || IsType.isNumber(_param)) {
            return Array
                .from(JSON.stringify(_param))
                .reduce((_s, _c) => window.Math.imul(31, _s) + _c.charCodeAt(0) | 0, 0);
        }

        throw new Error('Параметр для вычисления хеша не поддерживается!');
    },

    /**
     * Проверка на идентичность содержимого
     *
     * @param {Object|Array} _param1
     * @param {Object|Array} _param2
     * @return {boolean}
     * @throw Error
     */
    isEqual(_param1, _param2) {
        if (!IsType.isIterable(_param1) || !IsType.isIterable(_param2)) {
            throw new Error('Параметры для сравнения не поддерживаются!');
        }

        if (IsType.isArray(_param1)) {
            return this.hash(this.toArray(_param1).sort()) === this.hash(this.toArray(_param2).sort());
        }

        if (IsType.isSimpleObject(_param1) && !this.isEqual(Iterable.keys(_param1), Iterable.keys(_param2))) {
            return false;
        }

        return this.isEqual(this.toArray(_param1), this.toArray(_param2));
    },

    /**
     * Перевод байт в кб/мб/гб
     *
     * @param {Number} _param
     * @param {Number} _decimal
     * @return {String}
     * @throw Error
     */
    bytes(_param, _decimal = 2) {
        if (!IsType.isNumber(_param)) {
            throw new Error('Параметр для перевода байт в кб/мб/гб не поддерживается!');
        }
        if (!IsType.isNumber(_decimal) || _decimal < 0) {
            throw new Error('Параметр количества знаков для перевода байт в кб/мб/гб не поддерживается!');
        }

        if (_param === 0) {
            return '0 Bytes';
        }

        const kb    = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const pow   = window.Math.floor(window.Math.log(_param) / window.Math.log(kb));

        return Number.parseFloat((_param / (kb ** pow)).toFixed(_decimal)) + ' ' + sizes[pow];
    },

    /**
     * Форматированиепо шаблону
     * Поддерживается только %s
     *
     * @param {String} _param
     * @param {Array} _args
     * @return {string}
     * @throw Error
     */
    sprintf(_param, ..._args) {
        if (!IsType.isString(_param)) {
            throw new Error('Текст для форматирования не поддерживается!');
        }

        Iterable.each(_args, (_arg) => {
            if (!IsType.isString(_arg) && !IsType.isNumber(_arg)) {
                throw new Error('Один из аргументов не поддерживается!');
            }
        });

        let parts = _param;
        parts     = parts.split('%s');

        if (Iterable.count(_args) !== parts.length - 1) {
            throw new Error('Некорректное количество аргументов для форматирования!');
        }

        const result = [];
        Iterable.each(parts, (_part, _key, _index) => {
            result.push(_part);

            if (IsType.isDefined(_args[_index])) {
                result.push(_args[_index]);
            }
        });

        return result.join('');
    },

    /**
     * Выбор подходящего окончания слова
     *
     * @param {Number} _num
     * @param {String} _str1 одноГО, одноЙ
     * @param {String} _str2 двуХ
     * @param {String} _str3 десятИ
     * @return {String}
     * @throw Error
     */
    endWord(_num, _str1, _str2, _str3) {
        if (!IsType.isNumber(_num)) {
            throw new Error('Количество для форматирования окончания не поддерживается!');
        }
        if (!IsType.isString(_str1) || !IsType.isString(_str2) || !IsType.isString(_str3)) {
            throw new Error('Одно из окончаний для форматирования окончания не поддерживается!');
        }

        let val = _num % 100;

        if (val > 10 && val < 20) {
            return _str3;
        }

        val = _num % 10;

        if (val === 1) {
            return _str1;
        } else if (val > 1 && val < 5) {
            return _str2;
        }

        return _str3;
    },

    /**
     * Приводит число к нужному формату
     *
     * @param {Number} _num
     * @param {Number} _capacity
     * @param {String} _separatorInteger
     * @param {String} _separatorThousand
     * @return {String}
     * @throw Error
     */
    numberFormat(
        _num,
        _capacity          = 2,
        _separatorInteger  = ',',
        _separatorThousand = ' ',
    ) {
        if (!IsType.isNumber(_num)) {
            throw new Error('Неправильный тип передаваемого значения для форматирования числа!');
        }
        if (!IsType.isNumber(_capacity)) {
            throw new Error('Неправильный параметр округления для форматирования числа!');
        }
        if (!IsType.isString(_separatorInteger) || !IsType.isString(_separatorThousand)) {
            throw new Error('Неправильный параметр разделителя для форматирования числа!');
        }

        const parts = (new Intl.NumberFormat('en-EN', {maximumFractionDigits: 2})).formatToParts(_num);

        const groups = [];
        let fraction = '';
        Iterable.each(parts, (_part) => {
            if (_part.type === 'integer') {
                groups.push(_part.value);
            } else if (_part.type === 'fraction') {
                fraction = _part.value;
            }
        });

        return groups.join(_separatorThousand) + _separatorInteger + fraction.toString().padEnd(_capacity, '0');
    },

    /**
     * Переводит число в формат цены
     *
     * @param {Number} _price
     * @return {String}
     * @throw Error
     */
    price(_price) {
        return this.numberFormat(_price, 2, '.', ' ');
    },

};