'use strict';

import {readonly} from 'vue';

import Iterable from '@util/iterable';
import IsType   from '@util/is-type';
import Magic    from '@util/magic';
import Convert  from '@util/convert';

let listeners = {};

/**
 * Последние данные, отправленные через emit
 * Необходимо для "поздних" поключившихся слушателей
 */
const lastData = {};

/**
 * Проверка входных параметров
 *
 * @param {String} _name
 * @param {Function} _callback
 * @throw Error
 */
const checkParams = (_name, _callback) => {
    if (!IsType.isString(_name)) {
        throw new Error('Некорректное именование события системной шины!');
    }
    if (!IsType.isFunction(_callback)) {
        throw new Error('Некорректная функция обратного вызова системной шины!');
    }
};

export default readonly({
    /**
     * Подключение к прослушке события
     *
     * @param {String} _name
     * @param {Function} _callback
     * @throw Error
     */
    on(_name, _callback) {
        checkParams(_name, _callback);

        if (IsType.isUndefined(listeners[_name])) {
            listeners[_name] = {};
        }
        listeners[_name][_callback.toString()] = _callback;

        if (IsType.isDefined(lastData[_name])) {
            _callback(lastData[_name]);
        }
    },

    /**
     * Подключение к прослушке событий из списка
     * _callback будет вызван только при emit от всех событий
     * Деавтивируется после получения данных от всех прослушиваемых событий
     *
     * @param {Array|Object} _names
     * @param {Function} _callback
     * @throw Error
     */
    onAll(_names, _callback) {
        if (!IsType.isIterable(_names) || Iterable.isEmpty(_names)) {
            throw new Error('Некорректный массив имен события системной шины!');
        }
        const names = Convert.toArray(_names);

        Iterable.each(names, (_name) => {
            checkParams(_name, _callback);
        });

        const data      = {};
        const callbacks = {};

        const callCallback = () => {
            if (Convert.isEqual(Iterable.keys(data), names)) {
                Iterable.each(names, (_name) => {
                    this.off(_name, callbacks[_name]);
                });

                _callback(readonly(data));
            }
        };

        Iterable.each(names, (_name) => {
            callbacks[_name] = (_data) => {
                data[_name] = _data;

                callCallback();
            };

            this.on(_name, callbacks[_name]);
        });
    },

    /**
     * Отключение к прослушке события
     *
     * @param {String} _name
     * @param {Function} _callback
     * @throw Error
     */
    off(_name, _callback) {
        checkParams(_name, _callback);

        if (IsType.isDefined(listeners[_name])) {
            delete listeners[_name][_callback.toString()];
        }
        if (IsType.isDefined(lastData[_name])) {
            delete lastData[_name];
        }
    },

    /**
     * Триггер событий
     *
     * @param {String} _name
     * @param {unknown} _data
     * @throw Error
     */
    emit(_name, _data = {}) {
        checkParams(_name, Magic.createNoopFunction());

        const data      = readonly(_data);
        lastData[_name] = data;

        if (IsType.isUndefined(listeners[_name])) {
            return;
        }

        Iterable.each(listeners[_name], (_listener) => {
            _listener(data);
        });
    },
});

/**
 * Инициализаяи сервиса
 */
export const init = () => new Promise((_resolve) => {
    listeners = [];

    _resolve();
});