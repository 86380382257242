'use strict';

import IsType  from '@util/is-type';
import Convert from '@util/convert';

/**
 * Коллбеки по всем элементам
 *
 * @param {Object|Array} _param
 * @param {Function} _callback
 * @return {Boolean|null}
 * @throw Error
 */
const each = (_param, _callback) => {
    if (!IsType.isIterable(_param)) {
        throw new Error('Параметр для перебора не поддерживается!');
    }
    if (!IsType.isFunction(_callback)) {
        throw new Error('Параметр функции обратного вызова не поддерживается!');
    }

    let index = 0;
    for (const [key, value] of Object.entries(_param)) {
        if (_callback(value, key, index++) === null) {
            return null;
        }
    }

    return true;
};

/**
 * Поиск
 *
 * @param {Object|Array} _source
 * @param {Object} _where
 * @param {Number} _limit
 * @param {Boolean} _isReturnKey
 * @return {Array|String|Number}
 * @throw Error
 */
const findBy = (_source, _where, _limit, _isReturnKey = false) => {
    if (!IsType.isIterable(_source)) {
        throw new Error('Параметр для поиска не поддерживается!');
    }
    if (!IsType.isSimpleObject(_where)) {
        throw new Error('Параметр условия для поиска не поддерживается!');
    }

    let result = [];
    each(_source, (_value, _key) => {
        let isFoundAll = true;

        if (_value !== _where) {
            each(_where, (_whereValue, _whereKey) => {
                if (_value[_whereKey] !== _whereValue) {
                    isFoundAll = false;

                    return null;
                }
            });
        }

        if (isFoundAll) {
            if (_isReturnKey) {
                result = _key;

                return null;
            }

            result.push(_value);
        }
        if (result.length >= _limit) {
            return null;
        }
    });

    return result;
};

export default {
    /**
     * Отсортировать
     *
     * @param {Object|Array} _param
     * @param {Function} _callback
     * @return {Array}
     * @throw Error
     */
    sort(_param, _callback) {
        return Convert.toArray(_param).sort(_callback);
    },

    /**
     * Поиск
     *
     * @param {Object|Array} _value
     * @param {Object|Array} _param
     * @return {Boolean}
     * @throw Error
     */
    in(_value, _param) {
        return Convert.toArray(_param).includes(_value);
    },

    /**
     * Получить все включи
     *
     * @param {Object|Array} _param
     * @return {String[]}
     * @throw Error
     */
    keys(_param) {
        if (IsType.isIterable(_param)) {
            return Object.keys(_param);
        }

        throw new Error('Параметр для получения ключей не поддерживается!');
    },

    each,

    /**
     * Очистка, сохраняя ссылочную целостность
     *
     * @param {Object|Array} _param
     * @throw Error
     */
    clear(_param) {
        if (IsType.isArray(_param)) {
            _param.splice(0, _param.length);

            return;
        } else if (IsType.isSimpleObject(_param)) {
            this.each(_param, (_value, _key) => {
                delete _param[_key];
            });

            return;
        }

        throw new Error('Параметр для очистки не поддерживается!');
    },

    /**
     * Количество элементов
     *
     * @param {Object|Array} _param
     * @return {Number}
     * @throw Error
     */
    count(_param) {
        return this.keys(_param).length;
    },

    /**
     * Пуст ли?
     *
     * @param {Object|Array} _param
     * @return {Boolean}
     * @throw Error
     */
    isEmpty(_param) {
        return this.count(_param) === 0;
    },

    /**
     * Не пуст ли?
     *
     * @param {Object|Array} _param
     * @return {Boolean}
     * @throw Error
     * */
    isNotEmpty(_param) {
        return !this.isEmpty(_param);
    },

    /**
     * Клонирование
     *
     * @param {Object|Array} _param
     * @return {Object|Array}
     * @throw Error
     */
    clone(_param) {
        const isArray = IsType.isArray(_param);
        if (!isArray && !IsType.isSimpleObject(_param)) {
            throw new Error('Параметр для клонирования не поддерживается!');
        }

        let result = {};
        this.each(_param, (_value, _key) => {
            let value = _value;

            if (IsType.isArray(value) || IsType.isSimpleObject(value)) {
                value = this.clone(value);
            }
            // value = JSON.parse(JSON.stringify(value));

            result[_key] = value;
        });

        if (isArray) {
            result = Convert.toArray(result);
        }

        return result;
    },

    /**
     * Расширение
     *
     * @param {Object|Array} _destination
     * @param {Object|Array} _source
     * @return {Object|Array}
     * @throw Error
     */
    extend(_destination, _source) {
        if (!IsType.isIterable(_destination)) {
            throw new Error('Параметр назначения для расширения не поддерживается!');
        }
        if (!IsType.isIterable(_source)) {
            throw new Error('Параметр источника для расширения не поддерживается!');
        }
        const isArray = IsType.isArray(_destination);

        this.each(_source, (_value, _key) => {
            if (isArray) {
                _destination.push(_value);
            } else {
                _destination[_key] = _value;
            }
        });

        return _destination;
    },

    /**
     * Получение первой ячейки
     *
     * @param {Object|Array} _param
     * @param {null|unknown} _default
     * @return {unknown}
     * @throw Error
     */
    firstValue(_param, _default = null) {
        if (!IsType.isIterable(_param)) {
            throw new Error('Параметр для получения первой ячейки не поддерживается!');
        }

        let result = _default;
        this.each(_param, (_value) => {
            result = _value;

            return null;
        });

        return result;
    },

    /**
     * Получение последней ячейки
     *
     * @param {Object|Array} _param
     * @param {null|unknown} _default
     * @return {unknown}
     * @throw Error
     */
    lastValue(_param, _default = null) {
        if (!IsType.isIterable(_param)) {
            throw new Error('Параметр для получения первой ячейки не поддерживается!');
        }

        if (this.isEmpty(_param)) {
            return _default;
        }

        const result = Convert.toArray(_param);

        return result[result.length - 1];
    },

    /**
     * Получение предыдущего ключа
     *
     * @param {Object|Array} _param
     * @param {String|Number} _key
     * @param {null|unknown} _default
     * @return {String|Number}
     * @throw Error
     */
    prevKey(_param, _key, _default = null) {
        if (!IsType.isIterable(_param)) {
            throw new Error('Параметр для получения предыдущего ключа не поддерживается!');
        }
        if (!IsType.isString(_key) && !IsType.isDigit(_key)) {
            throw new Error('Текущий ключ для получения предыдущего ключа не поддерживается!');
        }

        let prevKey  = null;
        let foundKey = _default;

        each(_param, (_value, _valueKey) => {
            if (_key === _valueKey) {
                foundKey = prevKey;

                return null;
            }

            prevKey = _valueKey;
        });

        return foundKey;
    },

    /**
     * Получение предыдущего значения по ключу
     *
     * @param {Object|Array} _param
     * @param {String|Number} _key
     * @param {null|unknown} _default
     * @return {unknown}
     * @throw Error
     */
    prevValue(_param, _key, _default = null) {
        const key = this.prevKey(_param, _key, _default);
        if (key === _default) {
            return _default;
        }

        return _param[key];
    },

    /**
     * Получение следующего ключа
     *
     * @param {Object|Array} _param
     * @param {String|Number} _key
     * @param {null|unknown} _default
     * @return {String|Number}
     * @throw Error
     */
    nextKey(_param, _key, _default = null) {
        if (!IsType.isIterable(_param)) {
            throw new Error('Параметр для получения следующего ключа не поддерживается!');
        }
        if (!IsType.isString(_key) && !IsType.isDigit(_key)) {
            throw new Error('Текущий ключ для получения следующего ключа не поддерживается!');
        }

        let isNextItem = false;
        let foundKey   = _default;

        each(_param, (_value, _valueKey) => {
            if (isNextItem) {
                foundKey = _valueKey;

                return null;
            }

            if (_key === _valueKey) {
                isNextItem = true;
            }
        });

        return foundKey;
    },

    /**
     * Получение следующего значения по ключу
     *
     * @param {Object|Array} _param
     * @param {String|Number} _key
     * @param {null|unknown} _default
     * @return {unknown}
     * @throw Error
     */
    nextValue(_param, _key, _default = null) {
        const key = this.nextKey(_param, _key, _default);
        if (key === _default) {
            return _default;
        }

        return _param[key];
    },


    /**
     * Поиск
     *
     * @param {Object|Array} _param
     * @param {Object} _where
     * @return {Array}
     * @throw Error
     */
    findBy(_param, _where) {
        return findBy(_param, _where, Number.POSITIVE_INFINITY);
    },

    /**
     * Поиск и экстракция первого результата
     *
     * @param {Object|Array} _param
     * @param {Object} _where
     * @param {null|unknown} _default
     * @param {Boolean} _isReturnKey
     * @return {unknown}
     * @throw Error
     */
    findOneBy(_param, _where, _default = null, _isReturnKey = false) {
        const value = findBy(_param, _where, 1, _isReturnKey);

        if (_isReturnKey) {
            return value;
        }

        return this.firstValue(value, _default);
    },

    /**
     * Получение уникальных значений
     *
     * @param {Object|Array} _param
     * @return {Array}
     * @throw Error
     */
    unique(_param) {
        if (!IsType.isIterable(_param)) {
            throw new Error('Параметр для получения кникальных значений не поддерживается!');
        }
        const param = IsType.isArray(_param) ? _param : Convert.toArray(_param);

        return [...(new Set(param))];
    },
};