'use strict';

import {readonly, ref} from 'vue';

import Iterable from '@util/iterable';
import IsType   from '@util/is-type';
import Convert  from '@util/convert';


const lists = {
    success: 'success',
    info   : 'info',
    warning: 'warning',
    error  : 'error',
    simple : 'simple',
};

const messages = ref({});

const data = {
    messages,
    lists,

    /**
     * Очистка всех сообщений
     *
     * @param {String|null} _list
     * @throw Error
     */
    clearMessages(_list = null) {
        if (_list !== null) {
            if (!Iterable.in(_list, lists)) {
                throw new Error('Неизвестный список при очистки flash-message (' + _list + ')');
            }

            messages.value[_list] = {};
        } else {
            messages.value = {};
            Iterable.each(lists, (_messageList) => {
                messages.value[_messageList] = {};
            });
        }
    },

    /**
     * Удаление одного уведомления
     *
     * @param {Object} _message
     * @throw Error
     */
    deleteMessage(_message) {
        delete messages.value[_message.list][_message.hash];
    },

    /**
     * Добавление уведомления типа success
     *
     * @param {String} _message
     * @throw Error
     */
    addSuccessMessage(_message) {
        this.addMessage(lists.success, _message);
    },

    /**
     * Добавление уведомления типа info
     *
     * @param {String} _message
     * @throw Error
     */
    addInfoMessage(_message) {
        this.addMessage(lists.info, _message);
    },

    /**
     * Добавление уведомления типа warning
     *
     * @param {String} _message
     * @throw Error
     */
    addWarningMessage(_message) {
        this.addMessage(lists.warning, _message);
    },

    /**
     * Добавление уведомления типа error
     *
     * @param {String} _message
     * @throw Error
     */
    addErrorMessage(_message) {
        this.addMessage(lists.error, _message);
    },

    /**
     * Добавление уведомления типа simple
     *
     * @param {String} _message
     * @throw Error
     */
    addSimpleMessage(_message) {
        this.addMessage(lists.simple, _message);
    },

    /**
     * Добавление сообщения
     *
     * @param {String} _list
     * @param {String} _message
     * @throw Error
     * */
    addMessage(_list, _message) {
        if (!Iterable.in(_list, lists)) {
            throw new Error('Неизвестный список при добавлении хранилища уведомлений!');
        }
        if (!IsType.isString(_message)) {
            throw new Error('Некорректное сообщение хранилища уведомлений!');
        }

        const hash  = Convert.hash(_message);
        let message = messages.value[_list][hash];
        if (IsType.isUndefined(message)) {
            message = {
                message: _message,
                list   : _list,
                hash,
            };

            messages.value[_list][hash] = message;
        }
        message.sort  = window.performance.now();
        message.date  = new Date();
        message.isNew = true;

        window.setTimeout(() => {
            message.isNew = false;
        }, 500);
    },
};

/**
 * Методы управления хранилищем
 */
export default readonly(data);

/**
 * Инициализаия хранилища
 */
export const init = () => new Promise((_resolve) => {
    data.clearMessages();

    _resolve();
});