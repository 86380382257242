'use strict';

import httpBuildQuery from 'http-build-query';

import Iterable       from '@util/iterable';
import IsType         from '@util/is-type';
import DateUtil       from '@util/date';
import FlashMessenger from '@storage/flash-messenger';

import {readonly} from 'vue';

const options = {
    basePath: null,
    jwtToken: null,
};

// Разрешенные методы запроса
const allowMethods = {
    get : 'GET',
    post: 'POST',
};

// Список методов, при которых можно дополнительно отправлять данные в теле запроса
const allowBodyMethods = [allowMethods.post];

/**
 * Вывод флешмесседжей
 *
 * @param {Object} _json
 */
const addFlashMessages = (_json) => {
    if (IsType.isIterable(_json.messages)) {
        Iterable.each(_json.messages, (_message) => {
            FlashMessenger.addMessage(_message.type, _message.message);
        });
    }
};

/**
 * Запрос
 *
 * @param {String} _uri
 * @param {Object} _options
 * @return {Promise}
 */
const request = (_uri, _options) => new Promise((_resolve, _reject) => {
    (async () => {
        const uri = options.basePath + _uri;

        _options.cache       = 'no-cache';
        _options.cors        = 'same-origin';
        _options.credentials = 'include';

        try {
            const response = await window.fetch(uri, _options);

            if (response.ok) {
                const json = await response.json();

                addFlashMessages(json);

                if (json.success === true) {
                    _resolve(json);

                    return;
                }
            }

            const message = 'Ошибка в ответе HTTP-запроса: (' + response.status + ') - ' + response.statusText + '. URI: ' + uri;
            FlashMessenger.addErrorMessage(message);

            _reject(message);
        } catch (exception) {
            const message = 'Ошибка HTTP-запроса: ' + exception.message + '. URI: ' + uri;
            FlashMessenger.addErrorMessage(message);

            _reject(message);
        }
    })();
});

/**
 * Подготовка данных к отправке
 *
 * @param {FormData} _formData
 * @param {Object} _data
 * @param {String|null} _mainKey
 */
const prepareData = (_formData, _data, _mainKey = null) => {
    Iterable.each(_data, (_value, _key) => {
        let key = _mainKey === null ? _key : _mainKey + '[' + _key + ']';

        if (IsType.isBool(_value) || IsType.isNaN(_value) || _value === undefined || _value === null) {
            _formData.append(key, _value ? '1' : '0');
        } else if (IsType.isValidDate(_value)) {
            _formData.append(key, DateUtil.format(_value, DateUtil.formats.http));
        } else if (_value instanceof File) {
            key = key.replace(/[[\]]+(?<letter>[a-z])/ug, (_g, _letter) => _letter.toUpperCase());
            key = key.replace(/[[\]]/ug, '');

            _formData.append(key, _value);
        } else if (IsType.isIterable(_value)) {
            prepareData(_formData, _value, key);
        } else {
            _formData.append(key, _value.toString());
        }
    });
};

export default readonly({
    /**
     * Запрос к серверу
     *
     * @param {Object} _route
     * @param {Object} _data
     * @param {Array|Object} _files
     * @param {Object} _query
     * @return {Promise}
     * @throw Error
     */
    request(_route, _data = {}, _files = [], _query = {}) {
        if (!IsType.isSimpleObject(_route) || !IsType.isString(_route.method) || !IsType.isString(_route.uri)) {
            throw new Error('Маршрут запроса на сервер не поддерживается!');
        }
        if (!Iterable.in(_route.method, allowMethods)) {
            throw new Error('Метод отправки на сервер не поддерживается!');
        }
        if (!IsType.isIterable(_data) || !IsType.isIterable(_files) || !IsType.isIterable(_query)) {
            throw new Error('Передача некорректных данных/файлов на сервер не поддерживается!');
        }

        const optionsRequest = {
            method: _route.method,
        };

        let uri = _route.uri;
        if (Iterable.isNotEmpty(_query)) {
            uri += (uri.indexOf('?') === -1 ? '?' : '&') + httpBuildQuery(_query);
        }

        if (Iterable.isNotEmpty(_data) || Iterable.isNotEmpty(_files)) {
            if (!Iterable.in(_route.method, allowBodyMethods)) {
                throw new Error('Тело запроса может быть только у запросов с методами: ' + allowBodyMethods.join(', '));
            }

            const data = _data;
            Iterable.each(_files, (_file) => {
                if (!(_file instanceof File)) {
                    throw new Error('Данные для отправки на сервер содержат некорректный файл!');
                }
            });

            Iterable.extend(data, _files);

            const formData = new FormData();
            prepareData(formData, data);

            optionsRequest.body = formData;
        }

        return request(uri, optionsRequest);
    },
});

/**
 * Установка JWT токена для каждого запроса
 *
 * @param {String} _token
 */
export const setJwtToken = (_token) => {
    if (!IsType.isString(_token)) {
        throw new Error('Некорректный jwt токен сервиса удаленных запросов!');
    }
    options.jwtToken = _token;
};

/**
 * Инициализация сервиса
 *
 * @param {String} _basePath
 */
export const init = (_basePath) => new Promise((_resolve, _reject) => {
    if (!IsType.isString(_basePath)) {
        _reject(new Error('Некорректный относительный путь сервиса удаленных запросов!'));

        return;
    }
    options.basePath = _basePath;
    options.jwtToken = null;

    _resolve();
});

/**
 * Относительный путь
 *
 * @return {String}
 */
export const getBasePath = () => options.basePath;