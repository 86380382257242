'use strict';

import {readonly} from 'vue';

import Iterable from '@util/iterable';
import IsType   from '@util/is-type';

let prerenderEventName = null;

export default readonly({
    /**
     * Триггер события "страница готова к seo-пререндерингу"
     */
    prerenderReady() {
        window.document.dispatchEvent(new CustomEvent(prerenderEventName));
    },

    /**
     * Установка Title страницы
     *
     * @param {String} _title
     * @throw Error
     */
    setTitle(_title) {
        if (!IsType.isString(_title)) {
            throw new Error('Title страницы не поддерживается!');
        }
        window.document.title = _title;
    },

    /**
     * Установка одной страницы пререндеринга браузера
     *  Никак не связано с `prerenderReady`
     *
     * @param _uri
     * @throw Error
     */
    setPrerenderPage(_uri) {
        if (!IsType.isString(_uri)) {
            throw new Error('URI страницы пререндеринга не поддерживается!');
        }
        const existTags = window.document.querySelectorAll('link[rel=prerender]');
        Iterable.each(existTags, (_tag) => {
            _tag.parentNode.removeChild(_tag);
        });

        const newTag = window.document.createElement('link');
        newTag.rel   = 'prerender';
        // eslinter
        newTag.setAttribute('href', _uri);
        window.document.head.appendChild(newTag);
    },
});

/**
 * Инициализаия сервиса
 *
 * @param {String} _eventName
 */
export const init = (_eventName) => new Promise((_resolve, _reject) => {
    if (!IsType.isString(_eventName)) {
        _reject(new Error('Некорректное имя события пререндеринга сервиса управления мета-информацией страницы!'));

        return;
    }
    prerenderEventName = _eventName;

    _resolve();
});