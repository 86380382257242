'use strict';

import {defineAsyncComponent} from 'vue';

import Iterable from '@util/iterable';
import IsType   from '@util/is-type';

import FontAwesomeIcon from '@component/global/ui/font-awesome-icon';

import Button   from '@component/global/ui/form/button';
import Input    from '@component/global/ui/form/input';
import Checkbox from '@component/global/ui/form/checkbox';

const components = {
    Ui            : {
        FontAwesomeIcon: () => FontAwesomeIcon,
        Form           : {
            Button    : () => Button,
            Input     : () => Input,
            Checkbox  : () => Checkbox,
            TextEditor: () => defineAsyncComponent(() => import('@component/global/text-editor/index')),
        },
        Content        : () => defineAsyncComponent(() => import('@component/global/content/index')),
    },
    FlashMessenger: () => defineAsyncComponent(() => import('@component/global/flash-messenger/index')),
    Breadcrumbs   : () => defineAsyncComponent(() => import('@component/global/breadcrumb-list/index')),
    Paginator     : () => defineAsyncComponent(() => import('@component/global/paginator/index')),
    Select2       : () => defineAsyncComponent(() => import('vue3-select2-component')),
};

const set = (_components, _app, _prefix) => {
    Iterable.each(_components, (_lazyCallback, _name) => {
        const name = _prefix + _name;
        if (IsType.isFunction(_lazyCallback)) {
            _app.component(name, _lazyCallback());
        } else {
            set(_lazyCallback, _app, name);
        }
    });
};


export const init = (_app, _prefix) => {
    set(components, _app, _prefix);
};