'use strict';

import {createApp, readonly} from 'vue';

import AppStorage, {init as AppStorageInit}                       from '@storage/app';
import LocalStorageStorage, {init as LocalStorageInit}            from '@storage/local-storage';
import FlashMessengerStorage, {init as FlashMessengerStorageInit} from '@storage/flash-messenger';
import UserStorage, {init as UserStorageInit}                     from '@storage/user';

import ConsoleService, {init as ConsoleServiceInit}               from '@service/console';
import EventBusService, {init as EventBusServiceInit}             from '@service/event-bus/index';
import HttpService, {init as HttpServiceInit}                     from '@service/http';
import PageMetaService, {init as PageMetaServiceInit}             from '@service/page/meta';
import PageMediaQueryService, {init as PageMediaQueryServiceInit} from '@service/page/media-query';
import PageScrollService, {init as PageScrollServiceInit}         from '@service/page/scroll';

import IterableUtil from '@util/iterable';
import IsTypeUtil   from '@util/is-type';
import StringUtil   from '@util/string';
import DateUtil     from '@util/date';
import ConvertUtil  from '@util/convert';
import MagicUtil    from '@util/magic';

import LayoutApp                                        from '@component/base/layout';
import Router, {routes as RouteRoutes, uri as RouteUri} from '@root/router/index';
import {init as GlobalComponentsInit}                   from '@root/global-components';

export default (async () => {
    await ConsoleServiceInit();
    await AppStorageInit(process.env.NODE_ENV, window.Number(process.env.USER_CONFIG_VAT_PERCENT));
    await EventBusServiceInit();
    await FlashMessengerStorageInit();
    await LocalStorageInit();
    await HttpServiceInit(AppStorage.isProduction
        ? process.env.USER_CONFIG_HTTP_BASE_PATH_PROD
        : process.env.USER_CONFIG_HTTP_BASE_PATH_DEV);
    await UserStorageInit();
    await PageMetaServiceInit(process.env.USER_CONFIG_PRERENDER_DOCOMENT_EVENT);
    await PageMediaQueryServiceInit();
    await PageScrollServiceInit();

    const mixins = (() => readonly({
        IsType        : IsTypeUtil,
        Iterable      : IterableUtil,
        Convert       : ConvertUtil,
        Magic         : MagicUtil,
        String        : StringUtil,
        Date          : DateUtil,
        App           : AppStorage,
        EventBus      : EventBusService,
        LocalStorage  : LocalStorageStorage,
        FlashMessenger: FlashMessengerStorage,
        Routes        : RouteRoutes,
        Uri           : RouteUri,
        Http          : HttpService,
        User          : UserStorage,
        Console       : ConsoleService,
        Page          : {
            meta      : PageMetaService,
            mediaQuery: PageMediaQueryService,
            scroll    : PageScrollService,
        },
    }))();

    Router.beforeEach((_toRoute, _fromRoute, _next) => {
        PageMetaService.setTitle(_toRoute.meta.title);

        _next();
    });

    const app = createApp(LayoutApp);
    app.use(Router);
    GlobalComponentsInit(app, 'App');

    app.mixin({
        beforeCreate() {
            Object.assign(this, mixins);
        },
    });

    app.mount('#js-vue3-app');

    return app;
})();