'use strict';

import IsType from '@util/is-type';

export default {
    /**
     * Создание асинхронной функции, выбивающийся из потока, но выполняющейся сразу
     *
     * @param {Function} _callback
     * @param {Boolean} _isUseTimeout
     * @throw Error
     */
    createAsyncFunction(_callback, _isUseTimeout = false) {
        if (!IsType.isFunction(_callback)) {
            throw new Error('Параметр создания асинхронной функции не поддерживается!');
        }

        if (_isUseTimeout) {
            window.setTimeout(_callback, 0);

            return;
        }

        const fn = async () => {

        };

        fn()
            .then(() => {
                _callback();
            });
    },

    /**
     * Создание пустой функции для заглушек
     *
     * @return {Function}
     */
    createNoopFunction() {
        return () => {

        };
    },
};