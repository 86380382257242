'use strict';

import {readonly} from 'vue';

import IsType     from '@util/is-type';
import StringUtil from '@util/string';

export default {
    /**
     * Предопределенные форматы данных
     */
    formats: readonly({
        http   : 'Y-m-d H:i:s',
        default: 'Y-m-d H:i:s',
        date   : 'Y-m-d',
    }),
    /**
     * Форматирование даты
     *
     * @param {Date} _date
     * @param {String} _format
     * @return {String}
     * @throw Error
     */
    format(_date, _format = this.formats.default) {
        if (!IsType.isString(_format)) {
            throw new Error('Некорректный формат даты для форматирования!');
        }

        let format = _format;
        let date   = _date;
        if (IsType.isValidDate(date)) {
            date = new Date(date);
        } else if (IsType.isDigit(date)) {
            date = new Date(date * 1000);
        } else {
            throw new Error('Некорректная дата для форматирования!');
        }
        // date.setTime(date.getTime() + (60 * date.getTimezoneOffset() * 1000));

        format = format.replace('Y', date.getFullYear());

        format = format.replace('m', StringUtil.addPrefixZero(date.getMonth() + 1, 2));

        format = format.replace('d', StringUtil.addPrefixZero(date.getDate(), 2));

        format = format.replace('H', StringUtil.addPrefixZero(date.getHours(), 2));

        format = format.replace('i', StringUtil.addPrefixZero(date.getMinutes(), 2));

        format = format.replace('s', StringUtil.addPrefixZero(date.getSeconds(), 2));

        return format;
    },

};