'use strict';

import {defineAsyncComponent, readonly} from 'vue';
import {createRouter, createWebHistory} from 'vue-router';

import Iterable from '@util/iterable';
import IsType   from '@util/is-type';

import uriList from './uri';

const prepareRoutes = (_routes) => {
    const routes = {};
    Iterable.each(_routes, (_route) => {
        if (IsType.isDefined(_route.children)) {
            routes[_route.name]              = prepareRoutes(_route.children);
            routes[_route.name][_route.name] = _route;
        } else {
            routes[_route.name] = _route;
        }
    });

    return routes;
};

const keyPath   = 'path';
const routeList = [
    {
        name     : 'home',
        [keyPath]: '/',
        props    : {
            isMain: true,
        },
        meta     : {
            title: 'Загрузка...',
        },
        component: defineAsyncComponent(() => import('@component/home/index')),
    },
    {
        name     : 'auth',
        [keyPath]: '/auth',
        meta     : {
            title: 'Загрузка...',
        },
        component: defineAsyncComponent(() => import('@component/auth/index')),
        children : [
            {
                name     : 'login',
                [keyPath]: 'login',
                meta     : {
                    title: 'Авторизация',
                },
                component: defineAsyncComponent(() => import('@component/auth/login')),
            },
            {
                name     : 'logout',
                [keyPath]: 'logout',
                meta     : {
                    title: 'Выход',
                },
                component: defineAsyncComponent(() => import('@component/auth/logout')),
            },
        ],
    },
    {
        name     : 'admin',
        [keyPath]: '/admin',
        meta     : {
            title: 'Админ-панель',
        },
        component: defineAsyncComponent(() => import('@component/admin/index')),
    },
    {
        name     : 'blog',
        [keyPath]: '/blog',
        props    : {
            isMain: false,
        },
        meta     : {
            title: 'Блог',
        },
        children : [
            {
                name     : 'blogIndex',
                [keyPath]: '',
                meta     : {
                    title: 'Блог',
                },
                component: defineAsyncComponent(() => import('@component/blog/index')),
            },
            {
                name     : 'article',
                [keyPath]: 'article/:id(\\d+)/:name(.+)',
                props    : (_route) => ({
                    id: window.Number(_route.params.id),
                }),
                meta     : {
                    title: 'Загрузка...',
                },
                component: defineAsyncComponent(() => import('@component/blog/article/index')),
            },
            {
                name     : 'tag',
                [keyPath]: 'tag/:id(\\d+)/:name(.+)',
                props    : (_route) => ({
                    id: window.Number(_route.params.id),
                }),
                meta     : {
                    title: 'Загрузка...',
                },
                component: defineAsyncComponent(() => import('@component/blog/tag/index')),
            },
        ],
        component: defineAsyncComponent(() => import('@component/home/index')),
    },
    {
        name     : 'services',
        [keyPath]: '/services',
        props    : {
            isMain: false,
        },
        meta     : {
            title: 'Услуги',
        },
        children : [
            {
                name     : 'serviceIndex',
                [keyPath]: '',
                meta     : {
                    title: 'Услуги',
                },
                component: defineAsyncComponent(() => import('@component/service/index')),
            },
            {
                name     : 'service',
                [keyPath]: 'service/:id(\\d+)/:name(.+)',
                props    : (_route) => ({
                    id: window.Number(_route.params.id),
                }),
                meta     : {
                    title: 'Загрузка...',
                },
                component: defineAsyncComponent(() => import('@component/service/service')),
            },
        ],
        component: defineAsyncComponent(() => import('@component/home/index')),
    },
    {
        name     : 'trainings',
        [keyPath]: '/trainings',
        props    : {
            isMain: false,
        },
        meta     : {
            title: 'Тренинги',
        },
        children : [
            {
                name     : 'trainingIndex',
                [keyPath]: '',
                meta     : {
                    title: 'Тренинги',
                },
                component: defineAsyncComponent(() => import('@component/training/index')),
            },
            {
                name     : 'training',
                [keyPath]: 'training/:id(\\d+)/:name(.+)',
                props    : (_route) => ({
                    id: window.Number(_route.params.id),
                }),
                meta     : {
                    title: 'Загрузка...',
                },
                component: defineAsyncComponent(() => import('@component/training/training')),
            },
        ],
        component: defineAsyncComponent(() => import('@component/home/index')),
    },
    {
        [keyPath]: '/:pathMatch(.*)',
        name     : 'notFound',
        meta     : {
            title: 'Загрузка...',
        },
        component: defineAsyncComponent(() => import('@component/home/index')),
    },
];

export const routes = prepareRoutes(routeList);
export const uri    = readonly(uriList);

export default createRouter({
    history: createWebHistory(),
    routes : routeList,
    scrollBehavior(_toRoute, _fromRoute, _savedPosition) {
        if (_savedPosition) {
            return _savedPosition;
        }

        return {};
    },
});