'use strict';

import {library}         from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
    faAngleUp as fasAngleUp,
    faArrowCircleRight as fasArrowCircleRight,
    faChevronDown as fasChevronDown,
    faChevronUp as fasChevronUp,
    faExternalLinkAlt as fasExternalLinkAlt,
    faRubleSign as fasRubleSign,
    faSearch as fasSearch,
    faShareAlt as fasShareAlt,
    faTimes as fasTimes,
} from '@fortawesome/free-solid-svg-icons';

import {
    faFacebookF as fabFacebookF,
    faGoogle as fabGoogle,
    faInstagram as fabInstagram,
    faSkype as fabSkype,
    faTelegram as fabTelegram,
    faTwitter as fabTwitter,
    faViber as fabViber,
    faVk as fabVk,
    faWhatsapp as fabWhatsapp,
    faYoutube as fabYoutube,
} from '@fortawesome/free-brands-svg-icons';

import {
    faCalendarAlt as farCalendarAlt,
    faCheckSquare as farCheckSquare,
    faClock as farClock,
    faEye as farEye,
    faFrown as farFrown,
    faMeh as farMeh,
    faPaperPlane as farPaperPlane,
    faSmile as farSmile,
    faSmileWink as farSmileWink,
    faSquare as farSquare,
    faThumbsUp as farThumbsUp,
    faUser as farUser,
} from '@fortawesome/free-regular-svg-icons';

library.add(
    fasChevronUp,
    fasChevronDown,
    fasTimes,
    fasRubleSign,
    fasExternalLinkAlt,
    fasSearch,
    fasShareAlt,
    fasAngleUp,
    fasArrowCircleRight,
);
library.add(
    fabVk,
    fabInstagram,
    fabFacebookF,
    fabYoutube,
    fabGoogle,
    fabViber,
    fabSkype,
    fabWhatsapp,
    fabTwitter,
    fabTelegram,
);

library.add(
    farSquare,
    farCheckSquare,
    farCalendarAlt,
    farEye,
    farClock,
    farThumbsUp,
    farSmile,
    farSmileWink,
    farMeh,
    farFrown,
    farPaperPlane,
    farUser,
);

export default FontAwesomeIcon;