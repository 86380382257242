'use strict';

import {readonly} from 'vue';

import EventBusMain from '@service/event-bus/main';

// Названия событий
const nameEvents = {
    // Готовность страницы к сохранению слепка
    prerenderPage: {
        header: 'prerender:header',
        body  : 'prerender:body',
        footer: 'prerender:footer',
    },
};

/**
 * Триггеры и подписка на регулярные события
 */
export default readonly({
    nameEvents,
    prerenderPage: {
        onAll : (_callback) => {
            EventBusMain.onAll(nameEvents.prerenderPage, _callback);
        },
        header: {
            emit() {
                EventBusMain.emit(nameEvents.prerenderPage.header);
            },
        },
        body  : {
            emit() {
                EventBusMain.emit(nameEvents.prerenderPage.body);
            },
        },
        footer: {
            emit() {
                EventBusMain.emit(nameEvents.prerenderPage.footer);
            },
        },
    },
});

/**
 * Инициализация хранилища
 */
export const init = () => new Promise((_resolve) => {
    _resolve();
});