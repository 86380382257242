<template lang="pug">
span.btn.btn-primary(:class="{'button-cover': !isBootstrap, 'is-disabled': isDisabled}")
    slot
</template>

<style lang="stylus" scoped>
.button-cover
    display inline-block
    padding 0 14px
    border 1px solid transparent
    vertical-align middle
    text-align center
    text-decoration none
    line-height 24px
    cursor pointer
    transition 0.2s

    &:hover
        cursor pointer

    &.is-disabled
        background-color var(--text-background-consult-disabled)
        cursor not-allowed

</style>

<script>
'use strict';

export default {
    props: {
        isBootstrap: {
            type   : Boolean,
            default: false,
        },
        isDisabled : {
            type   : Boolean,
            default: false,
        },
    },
};
</script>