'use strict';

import {readonly} from 'vue';

import IsType from '@util/is-type';

export default readonly({
    /**
     * Перемещение к тегу
     * @param {Object} _tag
     * @param {Number} _shift
     * @throw Error
     */
    toTag(_tag, _shift = 0) {
        if (!IsType.isSimpleObject(_tag) || !IsType.isFunction(_tag.scrollIntoView)) {
            throw new Error('Тег скролла не поддерживается!');
        }

        if (!IsType.isDigit(_shift) || !IsType.isNumber(_shift)) {
            throw new Error('Смещение для скролла не поддерживается!');
        }

        const top = _tag.getBoundingClientRect().top + window.pageYOffset + _shift;

        window.scrollTo({
            top,
            behavior: 'smooth',
        });
    },
    /**
     * Перемещение к тегу по его селектору
     * @param {String} _selector
     * @param {Number} _shift
     * @throw Error
     */
    toSelector(_selector, _shift = 0) {
        if (!IsType.isString(_selector)) {
            throw new Error('Селектор тега скролла не поддерживается!');
        }
        const tag = window.document.querySelector(_selector);
        this.toTag(tag, _shift);
    },

    /**
     * Перемещение к началу страницы
     * @param {Number} _shift
     * @throw Error
     */
    toBody(_shift = 0) {
        const tag = window.document.querySelector('body');
        this.toTag(tag, _shift);
    },
});

/**
 * Инициализаяи сервиса
 */
export const init = () => new Promise((_resolve) => {
    _resolve();
});