'use strict';

import {readonly} from 'vue';

import IsType from '@util/is-type';

// Цвета для одноименных методов
const colors = {
    important: '#ff0000',
    warning  : '#e9824d',
    info     : '#01abe9',
};

export default readonly({
    /**
     * Печать в консоль
     *
     * @param {String} _text
     * @param {String} _color
     * @return Array
     * @throw Error
     */
    print(_text, _color) {
        if (!IsType.isString(_text)) {
            throw new Error('Некорректная строка для вывода в консоль!');
        }
        if (!IsType.isString(_color) || !(/^#[\da-f]{3,6}$/gui).test(_color)) {
            throw new Error('Некорректный цвет строки для вывода в консоль!');
        }

        const args = [
            '%c%s',
            'color: ' + _color,
            _text,
        ];

        window.console.log(...args);

        return args;
    },

    /**
     * Печать в консоль с цветом `colors.important`
     *
     * @param {String} _text
     * @throw Error
     */
    important(_text) {
        return this.print(_text, colors.important);
    },

    /**
     * Печать в консоль с цветом `colors.warning`
     *
     * @param {String} _text
     * @throw Error
     */
    warning(_text) {
        return this.print(_text, colors.warning);
    },

    /**
     * Печать в консоль с цветом `colors.info`
     *
     * @param {String} _text
     * @throw Error
     */
    info(_text) {
        return this.print(_text, colors.info);
    },

    /**
     * Печать в консоль с цветом `colors.info`
     * Дополнительно выводится таймер
     *
     * @return {Function}
     * @throw Error
     */
    time() {
        const startTime = window.performance.now();

        /**
         * Печать в консоль с цветом `colors.info`
         *
         * @param {String} _text
         * @throw Error
         */
        return (_text) => {
            const diffTime = (window.performance.now() - startTime).toFixed(0);

            return this.info(_text + ' (' + diffTime + ' мс)');
        };
    },
});

/**
 * Инициализаяи сервиса
 */
export const init = () => new Promise((_resolve) => {
    _resolve();
});