'use strict';

import {readonly} from 'vue';
import Iterable   from '@util/iterable';

import EventBusMainService, {init as EventBusMainServiceInit}     from '@service/event-bus/main';
import EventBusStaticService, {init as EventBusStaticServiceInit} from '@service/event-bus/static';

const eventBus = {
    static: EventBusStaticService,
};
Iterable.extend(eventBus, EventBusMainService);

export default readonly({
    static: EventBusStaticService,
    main  : EventBusMainService,
});

/**
 * Инициализаяи сервиса
 */
export const init = () => new Promise((_resolve, _reject) => {
    EventBusMainServiceInit()
        .then(() => {
            EventBusStaticServiceInit()
                .then(() => {
                    _resolve();
                })
                .catch(_reject);
        })
        .catch(_reject);
});