<template lang="pug">
div(:class="{'input-cover': !isBootstrap}")
    input.input.form-control(
        v-if="isTypeText"
        v-model="value"
        :class="classList"
        :type="type"
        :placeholder="placeholder"
        @input="setFilling"
    )

    input.input.form-control(
        v-if="isTypeNumber"
        v-model="value"
        :placeholder="placeholder"
        :class="classList"
        :type="type"
        :min="min"
        :max="max"
        :step="step"
        @input="setFilling"
    )

    input.input.form-control(
        v-if="isTypeDate"
        v-model="dateValue"
        :placeholder="placeholder"
        :class="classList"
        :type="type"
        @input="setFilling"
    )

    input.input.form-control(
        v-if="isTypeFile"
        ref="input"
        :class="classList"
        :type="type"
    )

    textarea.input.form-control(
        v-if="isTypeTextarea"
        v-model="value"
        :class="classList"
        :placeholder="placeholder"
        :rows="rows"
        @input="setFilling"
    )

    .invalid-feedback(v-if="isFilling && !isValid")
        div(
            v-for="error in errors"
            :key="error"
        ) {{error}}

    slot

</template>

<style lang="stylus" scoped>
.input-cover
    position relative
    width 100%

    .input
        position relative
        padding 10px 15px
        width 100%
        border 1px solid transparent
        border-radius 3px

        &.is-invalid
            border 1px solid var(--text-help-form-color-invalid)

    .invalid-feedback
        color var(--text-help-form-color-invalid)
        text-align left

.input
    height 100%
</style>

<script>
'use strict';

import {watch} from 'vue';

export default {
    props   : {
        placeholder: {
            type   : String,
            default: null,
        },
        type       : {
            type   : String,
            default: null,
        },
        min        : {
            type   : Number,
            default: Number.MIN_SAFE_INTEGER,
        },
        max        : {
            type   : Number,
            default: Number.MAX_SAFE_INTEGER,
        },
        step       : {
            type   : Number,
            default: 1,
        },
        rows       : {
            type   : Number,
            default: 3,
        },
        value      : {
            type   : [Number, String, Object],
            default: null,
        },
        isRequired : {
            type   : Boolean,
            default: false,
        },
        isMultiply : {
            type   : Boolean,
            default: false,
        },
        isBootstrap: {
            type   : Boolean,
            default: false,
        },
        debounce   : {
            type   : Number,
            default: null,
        },
        rules      : {
            type   : Object,
            default: null,
        },
    },
    emits   : ['update:value'],
    data() {
        return {
            startValue   : this.value,
            dateValue    : null,
            debounceTimer: null,
            isFilling    : false,
            files        : [],
        };
    },
    created() {
        watch(() => [this.value, this.dateValue, this.files], this.emitUpdateModel);

        if (this.isTypeDate) {
            watch(
                () => this.value,
                () => {
                    this.dateValue = this.Date.format(this.value, 'Y-m-dTH:i');
                },
                {immediate: true},
            );
        }
    },
    mounted() {
        if (this.isTypeFile) {
            this.$refs.input.addEventListener('change', (_eventChange) => {
                const resultFiles = [];
                const files       = _eventChange.target.files;

                this.Iterable.each(files, (_file) => {
                    const tmp       = _file.name.split('.');
                    const extension = tmp.pop().toLowerCase();
                    const name      = tmp.join('.');
                    const errors    = [];

                    if (this.IsType.isSimpleObject(this.rules)) {
                        if (this.rules.sizeMax && _file.size >= this.rules.sizeMax) {
                            const bytes = this.Convert.bytes(this.rules.sizeMax);
                            errors.push('Файл "' + _file.name + '" слишком большой! Допустимо до ' + bytes);
                        }
                        if (this.IsType.isIterable(this.rules.extensions)
                            && this.Iterable.isNotEmpty(this.rules.extensions)
                            && !this.Iterable.in(extension, this.rules.extensions)
                        ) {
                            const extensions = this.rules.extensions.join(', ');
                            errors.push('Файл "' + _file.name + '" имеет недопустимое расширение! Допустимы: ' + extensions);
                        }
                        if (this.rules.isInvalidName && name.length === 0) {
                            errors.push('Файл "' + _file.name + '" не имеет имени!');
                        }
                    }

                    resultFiles.push({
                        name,
                        extension,
                        errors,
                        type: _file.type,
                        size: _file.size,
                        file: _file,
                    });
                });

                this.setFilling();
                this.files = resultFiles;
            });
        }
    },
    computed: {
        isTypeText() {
            return this.type === 'text'
                || this.type === 'password'
                || this.type === 'email'
                || this.type === 'search'
                || this.type === 'tel';
        },
        isTypeTextarea() {
            return this.type === 'textarea';
        },
        isTypeEmail() {
            return this.type === 'email';
        },
        isTypeDate() {
            return this.type === 'date' || this.type === 'datetime-local' || this.type === 'time';
        },
        isTypeFile() {
            return this.type === 'file';
        },
        isTypeNumber() {
            return this.type === 'number';
        },
        errors() {
            let errors = [];

            if (this.isTypeFile) {
                this.Iterable.each(this.files, (_file) => {
                    errors = errors.concat(_file.errors);
                });
            } else if (this.isTypeEmail) {
                if (!(/^[\w._-]+@(?<host>[\w-]+\.)+[\w-]{2,}$/gu).test(this.value)) {
                    errors.push('Email заполнен некорректно!');
                }
            } else if (this.isTypeText || this.isTypeTextarea) {
                if (this.isRequired && (this.value === '' || this.value === null)) {
                    errors.push('Заполните корректно!');
                }
            } else if (this.isTypeNumber) {
                const value = Number(this.value);
                if (this.min !== Number.MIN_SAFE_INTEGER && value < this.min) {
                    errors.push('Число меньше ' + this.min + '!');
                }
                if (this.max !== Number.MAX_SAFE_INTEGER && value > this.max) {
                    errors.push('Число больше ' + this.max + '!');
                }
                if (value % this.step) {
                    errors.push('Число не кратно ' + this.step + '!');
                }
            }

            return errors;
        },
        isValid() {
            return this.Iterable.isEmpty(this.errors);
        },
        classList() {
            return {
                'is-invalid': this.isFilling && !this.isValid,
            };
        },
    },
    methods : {
        setFilling() {
            this.isFilling = true;
        },
        emitUpdateModel() {
            const callback = () => {
                let value = null;

                if (this.isTypeFile) {
                    value = this.isMultiply ? this.files : this.Iterable.firstValue(this.files);
                } else {
                    value = this.value;
                    if (this.isTypeNumber) {
                        value = Number(value);
                    } else if (this.isTypeDate) {
                        value = (new Date(this.dateValue)).getTime() / 1000;
                    }
                }

                if (this.isValid) {
                    this.$emit('update:value', value);
                }
            };

            if (this.debounce === null) {
                callback();
            } else {
                window.clearTimeout(this.debounceTimer);
                this.debounceTimer = window.setTimeout(callback, this.debounce);
            }
        },
    },
};
</script>