'use strict';

import {readonly, ref} from 'vue';

import Iterable from '@util/iterable';

const screenWidths = {
    '(min-width: 0px) and (max-width: 480px)'   : 'mobile',
    '(min-width: 481px) and (max-width: 900px)' : 'tabletSmall',
    '(min-width: 901px) and (max-width: 1200px)': 'tabletBig',
    '(min-width: 1201px)'                       : 'desktop',
};

const matches = ref({});

const match = () => {
    const newMatches = {};
    Iterable.each(screenWidths, (_key, _media) => {
        newMatches[_key] = window.matchMedia(_media).matches;
    });
    matches.value = newMatches;
};

/**
 * Состояния разрешений экрана
 */
export default readonly({
    is: matches,
});

/**
 * Инициализаяи сервиса
 */
export const init = () => new Promise((_resolve) => {
    match();

    Iterable.each(screenWidths, (_key, _media) => {
        window
            .matchMedia(_media)
            .addEventListener('change', (_event) => {
                if (!_event.matches) {
                    return;
                }
                match();
            });
    });

    _resolve();
});