'use strict';

/**
 * Получение типа переменной
 * @param {unknown} _param
 * @param {String} _param
 */

export default {
    /**
     * Вычисление типа данных
     *
     * @param _param
     * @return {String}
     */
    getType(_param) {
        return typeof _param;
    },

    /**
     * Проверка на итерируемость
     *
     * @param {unknown} _param
     * @return {Boolean}
     */
    isIterable(_param) {
        return this.isArray(_param) || this.isSimpleObject(_param);
    },

    /**
     * Это булево
     *
     * @param {unknown} _param
     * @param {Boolean} _isString
     * @return {Boolean}
     */
    isBool(_param, _isString = false) {
        if (_isString && (_param === 'true' || _param === 'false')) {
            return true;
        }

        return this.getType(_param) === 'boolean';
    },

    /**
     * Это объект даты
     *
     * @param {unknown} _param
     * @return {Boolean}
     */
    isDate(_param) {
        return _param instanceof Date;
    },

    /**
     * Это объект валидной даты
     *
     * @param {unknown} _param
     * @return {Boolean}
     */
    isValidDate(_param) {
        return this.isDate(_param) && !this.isNaN(_param.getTime());
    },

    /**
     * Это реальный NaN (без попытки конвертации)
     *
     * @param {unknown} _param
     * @return {Boolean}
     */
    isNaN(_param) {
        return Number.isNaN(_param);
    },

    /**
     * Это undefined
     *
     * @param {unknown} _param
     * @return {Boolean}
     */
    isDefined(_param) {
        return !this.isUndefined(_param);
    },

    /**
     * Это не undefined
     *
     * @param {unknown} _param
     * @return {Boolean}
     */
    isUndefined(_param) {
        return _param === undefined;
    },

    /**
     * Это null
     *
     * @param {unknown} _param
     * @return {Boolean}
     */
    isNull(_param) {
        return _param === null;
    },

    /**
     * Это массив
     *
     * @param {unknown} _param
     * @return {Boolean}
     */
    isArray(_param) {
        return Array.isArray(_param);
    },

    /**
     * Это объект
     *
     * @param {unknown} _param
     * @return {Boolean}
     */
    isSimpleObject(_param) {
        return _param !== null
            && this.getType(_param) === 'object'
            && !this.isArray(_param)
            && !this.isMap(_param)
            && !this.isSet(_param)
            && !this.isDate(_param);
    },

    /**
     * Это коллекция Map
     *
     * @param {unknown} _param
     * @return {Boolean}
     */
    isMap(_param) {
        return _param instanceof Map;
    },

    /**
     * Это коллекция Set
     *
     * @param {unknown} _param
     * @return {Boolean}
     */
    isSet(_param) {
        return _param instanceof Set;
    },

    /**
     * Это символ
     *
     * @param {unknown} _param
     * @return {Boolean}
     */
    isSymbol(_param) {
        return this.getType(_param) === 'symbol';
    },

    /**
     * Это функция
     *
     * @param {unknown} _param
     * @return {Boolean}
     */
    isFunction(_param) {
        return _param instanceof Function;
    },

    /**
     * Это корректное число (целое, дробное)(не NaN) с проверкой типа данных
     *
     * @param {unknown} _param
     * @return {Boolean}
     */
    isNumber(_param) {
        return this.getType(_param) === 'number' && !this.isNaN(_param);
    },

    /**
     * Это целое число в виде строки (или как есть)
     *
     * @param {unknown} _param
     * @return {Boolean}
     */
    isDigit(_param) {
        if (this.isNumber(_param) && Number.isInteger(_param)) {
            return true;
        }
        if (!this.isString(_param)) {
            return false;
        }

        return (/^\d+$/gu).test(_param.toString());
    },

    /**
     * Это строка
     *
     * @param {unknown} _param
     * @return {Boolean}
     */
    isString(_param) {
        return this.getType(_param) === 'string';
    },
};