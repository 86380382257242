<template lang="pug">
.icon(@click="toggleChecked")
    AppUiFontAwesomeIcon(
        v-if="isChecked"
        :icon="['far', 'check-square']"
    )
    AppUiFontAwesomeIcon(
        v-else
        :icon="['far', 'square']"
    )
    .slot
        slot

</template>

<style lang="stylus" scoped>
.icon
    cursor pointer

    .slot
        display inline-block
        margin-left 10px
</style>

<script>
'use strict';

export default {
    props  : {
        isSmall  : {
            type   : Boolean,
            default: false,
        },
        isChecked: {
            type    : Boolean,
            required: true,
        },
    },
    emits  : ['update:isChecked'],
    methods: {
        toggleChecked() {
            this.$emit('update:isChecked', !this.isChecked);
        },
    },
};
</script>