'use strict';

import {readonly, ref} from 'vue';

const profile = ref({});

export default readonly({
    /**
     * Профиль пользователя
     */
    profile,
});

/**
 * Инициализация хранилища
 *
 * @return {Promise}
 */
export const init = () => new Promise((_resolve) => {
    _resolve();
});